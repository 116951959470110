
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        limitType: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const regexList = [
            /^(\d{1,5}(\.\d{0,2})?)?$/
        ];

        let preInput = '';
        function handleInput(val: string) {
            const regex = regexList[props.limitType];
            if (!regex.test(val)) {
                updateModelValue(preInput);
            } else {
                preInput = val;
            }
            emit('input', val);
        }
        // 处理小数点后面没有数字的输入
        function handleBlur(event: FocusEvent) {
            const inputElement = event.target as HTMLInputElement;
            const value = inputElement.value;
            if (value.endsWith('.')) {
                updateModelValue(value.slice(0, -1));
            } else {
                updateModelValue(value);
            }
        }
        function updateModelValue(val: string) {
            emit('update:modelValue', val);
        }
        return {
            handleInput,
            handleBlur
        };
    }

});
