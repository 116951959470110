import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    onInput: _ctx.handleInput,
    onBlur: _ctx.handleBlur
  }, _createSlots({ _: 2 }, [
    (_ctx.$slots.prefix)
      ? {
          name: "prefix",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prefix")
          ])
        }
      : undefined,
    (_ctx.$slots.prepend)
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend")
          ])
        }
      : undefined,
    (_ctx.$slots.suffix)
      ? {
          name: "suffix",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "suffix")
          ])
        }
      : undefined
  ]), 1032, ["onInput", "onBlur"]))
}