
import { defineComponent, ref, toRefs } from 'vue';
import { setting, community } from '@/data';
import HttpRequest from '@/util/axios.config';
import { setting as settingRule } from '@/methods/rule';
import dayjs from 'dayjs';
import decimalInput from '@/components/common/decimal-input';

export default defineComponent({
    components: { decimalInput },
    setup() {
        const formRef = ref();
        const formData = ref<any>({
            CommunalFee: '1',
            DueDate: 1,
            FeatureSwitch: 0
        });
        const formRules = {
            CommunalFee: [
                { required: true, message: WordList.CommunalFeeSettingInputRule, trigger: 'blur' },
                { validator: settingRule.onlyPositiveNumber, trigger: 'blur' }
            ],
            DueDate: [
                {
                    validator: (rule: any, value: any, callback: any) => settingRule.limitUnableToday(rule, value, callback, initDueDate.value),
                    trigger: 'change'
                }
            ]
        };
        // DueDate下拉框选项
        const dueDateOptions = [];
        // 选项个数
        const dueDayCount = 10;
        for (let i = 1; i <= dueDayCount; i++) {
            dueDateOptions.push({ label: WordList.DayOfEveryMonth.format(dayjs().date(i).format('Do')), value: i });
        }
        // Build权限组列表
        const buildList = ref<{
            Name: string;
            ID: string;
            SelectedAccessGroupID: string[];
            AllAccessGroup: {
                label: string;
                options: {
                    ID: string;
                    Name: string;
                }[];
            }[];
        }[]>([]);
        // 获取Communal Fee设置信息
        interface GetProjectSettingCommunalFee {
            CommunalFee: string;
            DueDate: number;
            FeatureSwitch: number;
            Build: {
                Name: string;
                ID: string;
                SelectedAccessGroupID: {
                    ID: string;
                    Name: string;
                }[];
                AllAccessGroup: {
                    ID: string;
                    Name: string;
                    disabled?: boolean;
                }[];
            }[];
        }
        // 保存初始DueDate
        const initDueDate = ref(1);
        const getProjectSettingCommunalFeeApi = () => {
            setting.getProjectSettingCommunalFee((res: GetProjectSettingCommunalFee) => {
                Object.keys(formData.value).forEach((item) => {
                    const key = item as keyof GetProjectSettingCommunalFee;
                    formData.value[key] = res[key];
                });
                initDueDate.value = res.DueDate;
                originalFeature.value = res.FeatureSwitch;
                buildList.value = handleBuild(res.Build);
            });
        };

        // 处理所有权限组没有SelectedAccessGroupID对应的权限组
        function handleBuild(build: GetProjectSettingCommunalFee['Build']) {
            return build.map((item) => {
                const allAccessGroupUpdated: any = [{ options: [...item.AllAccessGroup] }, { label: WordList.InvalidAccessGroup, options: [] }];
                // 初始多选项
                const idList: string[] = [];
                // 遍历SelectedAccessGroupID，检查每个selected是否在AllAccessGroup中
                item.SelectedAccessGroupID.forEach((select) => {
                    const isInAllAccessGroup = item.AllAccessGroup.some((access) => access.ID === select.ID);
                    // 如果不在，则添加到失效的分组中
                    if (!isInAllAccessGroup) {
                        allAccessGroupUpdated[1].options.push({ ...select });
                    }
                    // 添加到初始多选数组中
                    idList.push(select.ID);
                });
                if (allAccessGroupUpdated[1].options.length === 0) {
                    allAccessGroupUpdated.pop();
                }
                return {
                    ...item,
                    AllAccessGroup: allAccessGroupUpdated,
                    SelectedAccessGroupID: idList
                };
            });
        }

        // 处理CommunalFeeFeature变化
        const isWarningVisible = ref(false);
        const isAllowDialog = ref(false);
        // 保存初始时的Feature，只有切换到和初始值不同的选项才发起请求
        const originalFeature = ref(0);
        const handleFeatureChange = (val: number) => {
            if (originalFeature.value !== val) {
                HttpRequest.post('v3/web/community/communalFee/checkFeatureSwitch', { FeatureSwitch: formData.value.FeatureSwitch },
                    (res: { data: {EnableFeatureSwitch: number}}) => {
                        // 可切换
                        if (res.data.EnableFeatureSwitch === 1) {
                            formData.value.FeatureSwitch = val;
                            // 切换到Off的情况
                            if (val === 0) {
                                isAllowDialog.value = true;
                            }
                        } else {
                        // 不可切换
                            isWarningVisible.value = true;
                            formData.value.FeatureSwitch = 1;
                        }
                    });
            }
        };

        // 处理Build权限组
        const handleBuildAccessGroup = (build: typeof buildList.value) => {
            const buildAccessGroup: string[] = [];
            build.forEach((item) => {
                const AccessGroup = item.SelectedAccessGroupID.join(';');
                if (formData.value.FeatureSwitch === 0) {
                    // 当关闭时，选中的权限组清空
                    buildAccessGroup.push(`${item.ID}-`);
                } else {
                    buildAccessGroup.push(`${item.ID}-${AccessGroup}`);
                }
            });
            return buildAccessGroup;
        };
        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const params = { ...formData.value, BuildAccessGroup: [] };
                    params.BuildAccessGroup = handleBuildAccessGroup(buildList.value);
                    HttpRequest.post(`v3/web/${community.ProjectType.value}/communalFee/editProjectSetting`, params, () => {
                        // 用于apartments相关模块
                        setting.getPMCommunityDetail((res: any) => {
                            community.defaultCommunalFee.value = res.CommunalFee;
                            community.isOpenCommunalFee.value = res.FeatureSwitch === 1;
                            community.communalDueDate.value = res.DueDate;
                        });
                        getProjectSettingCommunalFeeApi();
                    });
                }
            });
        };
        const initData = () => {
            getProjectSettingCommunalFeeApi();
        };
        initData();
        return {
            toRefs,
            formRef,
            formData,
            formRules,
            dueDateOptions,
            buildList,
            handleFeatureChange,
            isWarningVisible,
            isAllowDialog,
            submit
        };
    }
});
